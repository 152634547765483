import Toggle from "./toggle";

class UpdateViews {
    constructor() {
        this.isProcessing = false; // Prevent concurrent requests
    }

    ready() {
        // Attach listeners for HTMX events
        document.body.addEventListener("htmx:afterSwap", () => {
            this.reinitializeLogic();
        });

        document.body.addEventListener("htmx:afterOnLoad", () => {
            this.reinitializeLogic();
        });

        // Initial setup for static content
        this.reinitializeLogic();
    }

    reinitializeLogic() {
        // Reinitialize custom logic for toggles
        Toggle.ready();

        // Ensure HTMX attributes are re-applied
        htmx.process(document.body);

        // Reinitialize form listeners
        this.initializeFormListener();
    }

    initializeFormListener() {
        const forms = [
            { id: "form--account-create", listUrlAttr: "data-list-url", targetId: "#account-create-content", containerId: "#accounts", reset: true },
            { id: "form--account-update", listUrlAttr: "data-list-url", targetId: "#account-edit-content", containerId: "#accounts", reset: false },
            { id: "form--contract-create", listUrlAttr: "data-list-url", targetId: "#contract-create-content", containerId: "#contracts", reset: true },
            { id: "form--contract-update", listUrlAttr: "data-list-url", targetId: "#contract-edit-content", containerId: "#contracts", reset: false },
            { id: "form--product-create", listUrlAttr: "data-list-url", targetId: "#product-create-content", containerId: "#products", reset: true },
            { id: "form--product-update", listUrlAttr: "data-list-url", targetId: "#product-edit-content", containerId: "#products", reset: false },
            { id: "form--attachment-create", listUrlAttr: "data-list-url", targetId: "#attachment-create-content", containerId: "#attachments", reset: true },
            { id: "form--attachment-update", listUrlAttr: "data-list-url", targetId: "#attachment-edit-content", containerId: "#attachments", reset: false }
        ];

        forms.forEach((formConfig) => {
            const form = document.getElementById(formConfig.id);
            if (form) {
                // Remove existing listener to avoid duplication
                form.removeEventListener("submit", this.handleFormSubmit);

                // Attach new listener
                form.addEventListener("submit", (event) => this.handleFormSubmit(formConfig, event));
            }
        });
    }

    async handleFormSubmit(formConfig, event) {
        event.preventDefault();

        if (this.isProcessing) {
            console.warn("A request is already in progress, skipping.");
            return;
        }

        this.isProcessing = true;

        const form = event.target;
        const formData = new FormData(form);
        const csrfToken = form.querySelector("[name='csrfmiddlewaretoken']").value;
        const listUrl = form.getAttribute(formConfig.listUrlAttr);

        try {
            const response = await fetch(form.action, {
                method: "POST",
                headers: {
                    "X-CSRFToken": csrfToken,
                    "HX-Request": "true", // Mark request as HTMX to ensure compatibility
                },
                body: formData,
            });

            if (!response.ok) {
                throw new Error("Form submission failed");
            }

            const html = await response.text();

            // Parse and replace only the target part of the form response
            const parser = new DOMParser();
            const doc = parser.parseFromString(html, "text/html");
            const newContent = doc.querySelector(formConfig.targetId);

            if (newContent) {
                const target = document.querySelector(formConfig.targetId);
                if (target) {
                    target.outerHTML = newContent.outerHTML;

                    // Reinitialize logic after DOM replacement
                    this.reinitializeLogic();
                }
            }

            // else {
            //     console.warn(`${formConfig.targetId} not found in the response`);
            // }

            // Update the list container with HTMX behavior
            if (listUrl) {
                await this.swapContainerContent(listUrl, formConfig.containerId);
            } else {
                console.warn("List URL not provided");
            }
            if (formConfig.reset) {
                form.reset();
            }

        } catch (error) {
            console.error("Error:", error);
        } finally {
            this.isProcessing = false;
        }
    }

    async swapContainerContent(listUrl, containerId) {
        const targetElement = document.querySelector(containerId);
        if (!targetElement) {
            console.warn(`Container element ${containerId} not found, skipping swap.`);
            return;
        }

        try {
            // eslint-disable-next-line no-redeclare
            await htmx.ajax("GET", listUrl, {
                target: containerId,
                swap: "outerHTML",
                pushUrl: true,
                select: containerId,
            });

            // Reinitialize logic after HTMX container swap
            this.reinitializeLogic();
        } catch (error) {
            console.error(`Failed to fetch and update container: ${error.message}`);
        }
    }
}

export default new UpdateViews();
