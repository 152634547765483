class Form {
    ready() {

        let currentInputId = null;

        function preventEnterSubmissionAndTriggerHTMX(inputSelector) {
            // Attach the event handler to specified inputs for 'keypress'
            $(document).on('keypress', inputSelector, function (event) {
                // Check if the 'Enter' key is pressed
                if (event.key === 'Enter') {
                    // Prevent the default form submission behavior
                    event.preventDefault();

                    // Trigger HTMX directly on the input element by dispatching a custom event
                    htmx.trigger(this, 'keyup', {key: 'Enter'});
                }
            });

            // Attach the event handler for 'htmx:afterRequest' to clear the input after successful submission
            $(document).on('htmx:afterRequest', inputSelector, function (event) {
                // Check if the request was successful
                if (event.detail.successful) {
                    // Clear the input field value
                    $(this).val('');
                    $(this).closest('.form-group').removeClass('open');
                }
            });
        }

        function setupHTMXErrorHandler(inputId, errorContainerId) {
            // Listen for the htmx:beforeRequest event to capture the input element ID and clear any existing error messages
            $('body').on('htmx:beforeRequest', (event) => {
                const {detail} = event;

                // Capture the ID of the element that triggered the request
                currentInputId = detail.elt.id || null;

                // Clear the error message container before a new request is made
                const $errorMessageContainer = $(`#${errorContainerId}`);
                if ($errorMessageContainer.length) {
                    $errorMessageContainer.css('display', 'none').text('');
                }
            });

            // Listen for the htmx:afterRequest event on the body
            $('body').on('htmx:afterRequest', (event) => {
                const {xhr} = event.detail;  // Get the xhr object from the event
                const response = xhr.response;  // Get the response text

                // Check if the response content type is JSON
                const isJsonResponse = xhr.getResponseHeader('Content-Type')?.includes('application/json');

                // If the response is not JSON or the input IDs don't match, ignore the request
                if (!isJsonResponse || currentInputId !== inputId) {
                    return;
                }

                // Parse the JSON response
                let responseData;
                try {
                    responseData = JSON.parse(response);
                } catch (e) {
                    console.error("Error parsing JSON response:", e);
                    return;
                }

                // Handle successful responses by clearing any existing error messages
                if (responseData.success === true) {
                    const $errorMessageContainer = $(`#${errorContainerId}`);
                    if ($errorMessageContainer.length) {
                        $errorMessageContainer.css('display', 'none').text('');
                    }
                }

                // Check if the response contains an error message
                if (responseData.error || responseData.success === false) {
                    // Display the error message in the specified error container
                    const $errorMessageContainer = $(`#${errorContainerId}`);

                    if ($errorMessageContainer.length) {
                        // Make the error container visible and set its content
                        $errorMessageContainer.css('display', 'block').text(responseData.message);
                    } else {
                        console.error(`Error container with ID '${errorContainerId}' not found in the DOM.`);
                    }
                }

                // Reset `currentInputId` after the request is handled to avoid conflicts
                currentInputId = null;
            });
        }

        preventEnterSubmissionAndTriggerHTMX('#id_new_status_name');
        setupHTMXErrorHandler('id_new_status_name', 'error-message--status');

        preventEnterSubmissionAndTriggerHTMX('#id_new_account_manager_name');
        setupHTMXErrorHandler('id_new_account_manager_name', 'error-message--account-manager');

        preventEnterSubmissionAndTriggerHTMX('#id_new_lead_source_name');
        setupHTMXErrorHandler('id_new_lead_source_name', 'error-message--lead-source');

    }
}

export default new Form();