import { resizeTables } from "./resize-tables";

class Drawer {
    constructor() {
        this.lastOpenItem = null;
        this.ready();
    }

    ready() {
        resizeTables();
        this.attachEventHandlers();
        this.setupHTMXListeners();
        this.attachItemDeleteHandlers(); // Attach delete handlers initially
        this.attachNavLinkCloseHandler();
    }

    attachNavLinkCloseHandler() {
        const self = this;

        $('.nav-link').on('click', function () {
            self.closeDrawer(); // Close the drawer when a nav-link is clicked
        });
    }

    setupHTMXListeners() {
        $(document).off('htmx:afterOnLoad', this.handleHTMXLoad.bind(this));
        $(document).on('htmx:afterOnLoad', this.handleHTMXLoad.bind(this));
        $(document).off('htmx:afterSwap', this.handleHTMXSwap.bind(this));
        $(document).on('htmx:afterSwap', this.handleHTMXSwap.bind(this));
    }

    handleHTMXLoad(event) {
        if (event.detail.target.id === 'drawer-wrapper') {
            $('.drawer-wrapper').addClass('drawer-wrapper--open');
            setTimeout(() => {
                this.attachDrawerCloseHandlers();
                this.setFocusOnCloseButton();
                this.attachItemDeleteHandlers(); // Attach delete handlers after HTMX load
            }, 100);
        }
        this.attachEventHandlers(); // Reattach event handlers after HTMX load
        resizeTables(); // Reattach table resizing logic
    }

    handleHTMXSwap(event) {
        if (event.detail.target.id === 'main-content') {
            this.attachItemDeleteHandlers(); // Reattach delete handlers after HTMX swap
            this.attachStopPropagation(); // Reattach logic for file links
            resizeTables(); // Reattach table resizing logic
        }
    }

    attachEventHandlers() {
        const self = this;

        this.attachStopPropagation();

        $('.drawer__open')
            .off('click keypress')  // Remove any previously attached handlers
            .on('click', function () {
                self.lastOpenItem = $(this);
                self.openDrawer();
            })
            .on('keypress', function (event) {
                if (event.key === 'Enter') {
                    event.preventDefault();  // Prevent default behavior
                    $(this).trigger('click');
                }
            });


        this.attachDrawerCloseHandlers();

           // Attach full page transition event for .drawer__open--full
        $('.drawer__open--full')
            .off('click')  // Remove any previously attached handlers
            .on('click', function (event) {
                event.preventDefault();
                self.fullPageTransition($(this));
            });
    }

     attachStopPropagation() {
        $('.stop-propagation-js')
            .off('click')
            .on('click', function (event) {
                event.stopPropagation(); // Prevent bubbling to parent row
            });
    }

    fullPageTransition(button) {
       if (typeof document.startViewTransition === 'function') {
        // Using View Transitions API to create smooth transition effect
        document.startViewTransition(() => {
            // Trigger HTMX to load content into #main-content
            button.trigger('click');
            this.closeDrawer();

        });
    } else {
           // Fallback for older browsers without View Transitions API support
           // Trigger HTMX to load content into #main-content
           button.trigger('click');
           this.closeDrawer();
       }
    }

    attachDrawerCloseHandlers() {
        const self = this;
        $('.drawer__close')
            .off('click keypress')  // Remove any previously attached handlers
            .on('click', function () {
                self.closeDrawer();
            })
            .on('keypress', function (event) {
                if (event.key === 'Enter') {
                    $(this).click();
                }
            });
    }

    attachItemDeleteHandlers() {
        const self = this;
        $('.delete__item')
            .off('click keypress')
            .on('click', function (event) {
                event.stopPropagation(); // Ensure the event does not bubble up
                self.openConfirmation(event, $(this));
            })
            .on('keypress', function (event) {
                if (event.key === 'Enter') {
                    event.preventDefault();  // Prevent default behavior
                    self.openConfirmation(event, $(this));
                }
            });

        $('.delete__item--close')
            .off('click keypress')
            .on('click', function (event) {
                event.stopPropagation(); // Ensure the event does not bubble up
                self.closeConfirmation(event, $(this));
            })
            .on('keypress', function (event) {
                if (event.key === 'Enter') {
                    event.preventDefault();  // Prevent default behavior
                    self.closeConfirmation(event, $(this));
                }
            });

        $('.delete__confirmation')
            .off('click keypress')
            .on('click', function (event) {
                event.stopPropagation();
            });
    }

    openConfirmation(event, element) {
        event.stopPropagation();
        element.next().addClass('delete__confirmation--active');
    }

    closeConfirmation(event, element) {
        event.stopPropagation();
        element.parent().removeClass('delete__confirmation--active');
    }

    openDrawer() {
        // Wait for the HTMX request to complete and DOM to update
        $(document).on('htmx:afterSwap', (event) => {
            if (event.detail.target.id === 'drawer-wrapper') {
                setTimeout(() => {
                    this.attachDrawerCloseHandlers();
                    this.attachItemDeleteHandlers(); // Attach delete handlers after HTMX swap
                    this.setFocusOnCloseButton();
                }, 100);
            }
        });
    }

    setFocusOnCloseButton() {
        const closeButton = $('.drawer__close');
        if (closeButton.length > 0) {
            closeButton.focus();
        }
    }

    closeDrawer() {
        $('.drawer-wrapper').removeClass('drawer-wrapper--open').empty();
        if (this.lastOpenItem) {
            this.lastOpenItem.focus();
        }
    }
}

export default new Drawer();