// This is the scss entry file
// We can import other JS file as we like
import Jumbotron from "./components/jumbotron";
import "../styles/index.scss";
import Drawer from "./components/drawer";
// import Toggle from "./components/toggle";
import Form from "./utils/form";
import { togglePasswordVisibility } from "./components/togglePasswordVisibility";
import UpdateViews from "./components/update-views";


$(document).ready(function () {

    // Find elements and initialize
    $(Jumbotron.selector()).each(function () {
        new Jumbotron(this);
    });

    Drawer.ready();
    // Toggle.ready();
    Form.ready();
    UpdateViews.ready();
    togglePasswordVisibility();
});


