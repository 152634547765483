class Toggle {
    ready() {
        function toggleCollapse($button) {
            const targetId = $button.data('target');
            if (!targetId) return;

            const $targetElement = $(`${targetId}`);
            if (!$targetElement.length) return;

            $targetElement.toggleClass('open');
            const isOpen = $targetElement.hasClass('open');

            // Actions when the element is being closed
            if (!isOpen) {
                clearInputAndErrorMessage($button);
            }

            // Update aria attributes
            $button.attr('aria-expanded', isOpen.toString());
            $button.attr('aria-controls', targetId);
        }

        function clearInputAndErrorMessage($button) {
            const $closestInput = $button.closest('.form-group').find('.form-control');
            $closestInput.val('');

            const $closestErrorMessage = $button.closest('.form-group').find('.error-message');
            $closestErrorMessage.text('');
        }

        function attachEventListeners() {
            $('[data-target]').off('click').on('click', function () {
                toggleCollapse($(this));
            });
        }

        // Attach event listeners on document ready
        attachEventListeners();

    }
}

export default new Toggle();