export const resizeTables = () => {
    const tables = document.querySelectorAll(".table");

    if (!tables.length) return;

    tables.forEach((table) => {
        const tableId = table.id; // Use the table's `id` directly
        if (!tableId) {
            console.warn("Table is missing an id attribute.");
            return;
        }

        // Restore stored column widths
        restoreColumnWidths(table, tableId);

        table.querySelectorAll("th").forEach((header, index) => {
            if (index === table.rows[0].cells.length - 1) return; // Skip the last column

            if (header.querySelector(".resizer")) return; // Skip if resizer already exists

            const resizer = document.createElement("div");
            resizer.classList.add("resizer");
            header.appendChild(resizer);

            resizer.addEventListener("mousedown", (e) => {
                e.preventDefault();

                const startX = e.clientX;
                const startWidth = header.offsetWidth;

                const onMouseMove = (e) => {
                    const widthDiff = e.clientX - startX;
                    const newWidth = startWidth + widthDiff;
                    header.style.width = `${newWidth}px`;

                    // Adjust column width for all rows
                    table.querySelectorAll("tr").forEach((row) => {
                        const cell = row.cells[index];
                        if (cell) {
                            cell.style.width = `${newWidth}px`;
                        }
                    });

                    // Save the updated width
                    saveColumnWidth(tableId, index, newWidth);
                };

                const onMouseUp = () => {
                    document.removeEventListener("mousemove", onMouseMove);
                    document.removeEventListener("mouseup", onMouseUp);
                };

                document.addEventListener("mousemove", onMouseMove);
                document.addEventListener("mouseup", onMouseUp);
            });
        });
    });
};

const PROJECT_NAME = "mansion"; // Project-specific prefix

const saveColumnWidth = (tableId, columnIndex, width) => {
    const storageKey = `${PROJECT_NAME}-tableWidths`; // Unique storage key
    const storedWidths = JSON.parse(localStorage.getItem(storageKey)) || {};
    storedWidths[tableId] = storedWidths[tableId] || {};
    storedWidths[tableId][columnIndex] = width;
    localStorage.setItem(storageKey, JSON.stringify(storedWidths));
};

const restoreColumnWidths = (table, tableId) => {
    const storageKey = `${PROJECT_NAME}-tableWidths`; // Unique storage key
    const storedWidths = JSON.parse(localStorage.getItem(storageKey)) || {};
    const columnWidths = storedWidths[tableId];
    if (columnWidths) {
        table.querySelectorAll("th").forEach((header, index) => {
            if (columnWidths[index]) {
                const width = columnWidths[index];
                header.style.width = `${width}px`;
                table.querySelectorAll("tr").forEach((row) => {
                    const cell = row.cells[index];
                    if (cell) {
                        cell.style.width = `${width}px`;
                    }
                });
            }
        });
    }
};
