export function togglePasswordVisibility() {
    $(document).on("click", ".btn-show", function () {
        const container = $(this).closest(".form-input__container");
        const input = container.find("input");
        input.attr("type", "text"); // Show password
        container.find(".btn-show").hide();
        container.find(".btn-hide").show();
    });

    $(document).on("click", ".btn-hide", function () {
        const container = $(this).closest(".form-input__container");
        const input = container.find("input");
        input.attr("type", "password"); // Hide password
        container.find(".btn-hide").hide();
        container.find(".btn-show").show();
    });

    
}
